<template>
  <div class="layout--default flex flex-col min-h-screen">
    <slot />
  </div>

  <LocaleSidebar />
  <CartSidebar />
  <WishlistSidebar />
  <ProductSizeChartSidebar />
  <Notification />

  <VariantSelectionModal />
  <SoldOutFormModal />
</template>

<script setup lang="ts">
await useAsyncData('fixQueryParams', async (nuxtApp) => {
  delete nuxtApp?.payload?.path
  return true
})

useHead({
  htmlAttrs: {
    class: 'theme--base',
  },
})
</script>
